p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}
img {
  max-width: 100%;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
}

.auth-wrapper {
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #accffe; */
  /* background: #a0e3cf; */
  background: #fff;
  width: 100vw;
  /* background-image: url('../../assets/images/zap70.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover; */
}

.inner {
  position: relative;
  width: 435px;
}

.image-1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99;
}

.image-2 {
  position: absolute;
  bottom: 0;
  /* right: -129px; */
  right: -300px;
}

.form {
  width: 100%;
  position: relative;
  z-index: 9;
  padding: 50px 55px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

h2 {
  text-transform: uppercase;
  font-size: 30px;
  color: #333;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 33px;
}
h3 {
  text-transform: uppercase;
  font-size: 25px;
  color: #333;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 33px;
}

.form-holder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-holder span {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 15px;
  color: #333;
  z-index: 10;
}
.form-holder span.lnr-lock {
  left: 2px;
}

.form-input {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  width: 100%;
  height: 38px;
  background: none;
  padding: 3px 42px 0px;
  color: #333 !important;
  font-size: 16px;
}
.form-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #333 !important;
  transform: translateY(1px);
}
.form-input::-moz-placeholder {
  font-size: 14px;
  color: #333 !important;
  transform: translateY(1px);
}
.form-input:-ms-input-placeholder {
  font-size: 14px;
  color: #333 !important;
  transform: translateY(1px);
}
.form-input:-moz-placeholder {
  font-size: 14px;
  color: #333 !important;
  transform: translateY(1px);
}
.form-input:focus {
  border-bottom: 3px solid #a0e3cf;
  margin-left: 0 !important;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}
.form-input:focus .is-invalid {
  box-shadow: none;
}

.button {
  border: none;
  width: 100%;
  height: 49px;
  margin-top: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /* background: #99ccff; */
  background: #89dcc3;
  color: #000;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
}
.button span {
  position: relative;
  z-index: 2;
}
.button:before,
.button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background-color: rgba(52, 152, 253, 0.25); */
  background-color: rgba(137, 220, 195, 0.55);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button:after {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.button:hover:before,
.button:hover:after {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@media (max-width: 991px) {
  .inner {
    width: 400px;
    left: 4%;
  }
}
@media (max-width: 767px) {
  .inner {
    width: 350px;
    left: 0;
  }

  .image-1,
  .image-2 {
    display: none;
  }

  .form {
    padding: 35px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
  }

  .auth-wrapper {
    background: none;
  }
}
